<template>
  <div class="cash-withdrawal">
    <div class="nav acea-row">
      <div
        v-for="(item, index) in navList"
        class="item font-color-red"
        @click="swichNav(index, item)"
        :key="index"
      >
        <div class="line bg-color-red" :class="currentTab === index ? 'on' : ''"></div>
        <div class="iconfont" :class="item.icon + ' ' + (currentTab === index ? 'on' : '')"></div>
        <div>{{ item.name }}</div>
      </div>
    </div>
    <div class="wrapper">
      <div :hidden="currentTab !== 0" class="list">
        <div class="item acea-row row-between-wrapper">
          <div class="name">微信号</div>
          <div class="input">
            <input placeholder="请填写微信号" v-model="post.weixin" />
          </div>
        </div>
        <div class="item acea-row row-between-wrapper">
          <div class="name">提现</div>
          <div class="input">
            <input :placeholder="'最低提现金额' + minPrice" v-model="post.money" />
          </div>
        </div>
        <div class="tip">当前可提现金额: {{ commissionCount }}</div>
        <div class="bnt bg-color-red" @click="submitted(0)">提现</div>
      </div>
      <div :hidden="currentTab !== 1" class="list">
        <div class="item acea-row row-between-wrapper">
          <div class="name">用户名</div>
          <div class="input">
            <input placeholder="请填写您的支付宝用户名" v-model="post.name" />
          </div>
        </div>
        <div class="item acea-row row-between-wrapper">
          <div class="name">账号</div>
          <div class="input">
            <input placeholder="请填写您的支付宝账号" v-model="post.alipay_code" />
          </div>
        </div>
        <div class="item acea-row row-between-wrapper">
          <div class="name">提现</div>
          <div class="input">
            <input :placeholder="'最低提现金额' + minPrice" v-model="post.money" />
          </div>
        </div>
        <div class="tip">当前可提现金额: {{ commissionCount }}</div>
        <div class="bnt bg-color-red" @click="submitted(1)">提现</div>
      </div>
    </div>
  </div>
</template>
<script>
import Generalize from "@api/generalize";
export default {
  name: "UserCash",
  components: {},
  props: {},
  data: function() {
    return {
      navList: [
        { name: "微信", type: "0", icon: "icon-weixin2" },
        { name: "支付宝", type: "1", icon: "icon-icon34" }
      ],
      post: {
        extract_type: "0",
        alipay_code: "",
        money: "",
        name: "",
        bankname: "",
        cardnum: "",
        weixin: ""
      },
      currentTab: 0,
      minPrice: 0,
      banks: [],
      commissionCount: 0
    };
  },
  mounted: function() {
    // this.getBank();
    this.commissionCount = this.$route.query.money;
  },
  methods: {
    swichNav: function(index, item) {
      this.currentTab = index;
      this.post.extract_type = item.type;
    },
    async submitted(idx) {
      console.log(idx)
      let money = "";
      let account = "";
      try {
        if (this.post.money > this.commissionCount || this.post.money < 0) {
          throw "请重新输入金额";
        } else {
          if (idx == 0) {
            money = this.post.money;
            account = this.post.weixin;
          } else {
            money = this.post.money;
            account = this.post.alipay_code;
          }
        }
        const res = await Generalize.Withdrawal(
          money,
          account,
          this.post.extract_type
        );
        if(res.code == 200) {
          this.$toast("提交成功")
          this.$router.go(-1)
        } else {
          this.$toast(res.msg)
        }
        console.log(res);
      } catch (error) {
        this.$toast(error);
      }
    },
    save: function() {}
  }
};
</script>

<style lang="scss" scoped>
@import "../index.scss";
/*银行卡提现*/
.cash-withdrawal {
  height: 100%;
  background: #fff;
}
.cash-withdrawal .nav {
  height: 65px;
  box-shadow: 0 5px 5px #f8f8f8;
  -webkit-box-shadow: 0 5px 5px #f8f8f8;

  -moz-box-shadow: 0 5px 5px #f8f8f8;
  -o-box-shadow: 0 5px 5px #f8f8f8;
}

.cash-withdrawal .nav .item {
  font-size: 14px;
  flex: 1;
  -webkit-flex: 1;
  -o-flex: 1;
  -ms-flex: 1;
  text-align: center;
}

.cash-withdrawal .nav .item ~ .item {
  border-left: 1px solid #f0f0f0;
}

.cash-withdrawal .nav .item .iconfont {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 1px solid #e93323;
  text-align: center;
  line-height: 14px;
  margin: 0 auto 3px auto;
  font-size: 12px;
}

.cash-withdrawal .nav .item .iconfont.on {
  background-color: #e93323;
  color: #fff;
  border-color: #e93323;
}

.cash-withdrawal .nav .item .line {
  width: 1px;
  height: 10px;
  margin: 0 auto;
  transition: height 0.3s;
  -webkit-transition: height 0.3s;
  -moz-transition: height 0.3s;
  -o-transition: height 0.3s;
}

.cash-withdrawal .nav .item .line.on {
  height: 20px;
}

.cash-withdrawal .wrapper .list {
  padding: 0 15px;
}

.cash-withdrawal .wrapper .list .item {
  border-bottom: 1px solid #eee;
  height: 53px;
  font-size: 15px;
  color: #333;
}

.cash-withdrawal .wrapper .list .item .name {
  width: 65px;
}

.cash-withdrawal .wrapper .list .item .input {
  width: 253px;
}

.cash-withdrawal .wrapper .list .item .input input::placeholder {
  color: #bbb;
}

.cash-withdrawal .wrapper .list .tip {
  font-size: 14px;
  color: #999;
  margin-top: 13px;
}

.cash-withdrawal .wrapper .list .bnt {
  font-size: 16px;
  color: #fff;
  width: 345px;
  height: 45px;
  text-align: center;
  border-radius: 25px;
  line-height: 45px;
  margin: 32px auto;
}

.cash-withdrawal .wrapper .list .tip2 {
  font-size: 14px;
  color: #999;
  text-align: center;
  margin: 22px 0 10px 0;
}

.cash-withdrawal .wrapper .list .value {
  height: 68px;
  line-height: 68px;
  border-bottom: 1px solid #eee;
  width: 345px;
  margin: 0 auto;
}

.cash-withdrawal .wrapper .list .value input {
  font-size: 40px;
  color: #282828;
  height: 68px;
  text-align: center;
  width: 100%;
}

.cash-withdrawal .wrapper .list .value input::placeholder {
  color: #bbb;
}
</style>
